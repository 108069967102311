export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const ADD_POST = "ADD_POST";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const GET_INDICATORS = "GET_INDICATORS";
export const GET_AUTHORS = "GET_AUTHORS";
export const GET_USERS = "GET_USERS";
export const ADD_AUTHOR = "ADD_AUTHOR";
export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const UPDATE_INDICATORS = "UPDATE_INDICATORS";

export const SET_USER = "SET_USER";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const GET_GRAFICOSS = "GET_GRAFICOSS";
export const GET_GRAFICOS = "GET_GRAFICOS";
export const ADD_GRAFICOS = "ADD_GRAFICOS";
export const DELETE_GRAFICOS = "DELETE_GRAFICOS";
export const UPDATE_GRAFICOS = "UPDATE_GRAFICOS";

export const GET_FUNDAMENTOSS = "GET_FUNDAMENTOSS";
export const GET_FUNDAMENTOS = "GET_FUNDAMENTOS";
export const ADD_FUNDAMENTOS = "ADD_FUNDAMENTOS";
export const DELETE_FUNDAMENTOS = "DELETE_FUNDAMENTOS";
export const UPDATE_FUNDAMENTOS = "UPDATE_FUNDAMENTOS";

export const GET_REFERRALS = "GET_REFERRALS";
export const REFERRALS_ERROR = "REFERRALS_ERROR";
export const SEARCH_REFERRALS_ERROR = "SEARCH_REFERRALS_ERROR";
export const SEARCH_REFERRALS_SUCCESS = "SEARCH_REFERRALS_SUCCESS";

export const GET_CURATED_PROFILES = "GET_CURATED_PROFILES";
export const CREATE_CURATED_PROFILE = "CREATE_CURATED_PROFILE";
export const UPDATE_CURATED_PROFILE = "UPDATE_CURATED_PROFILE";
export const CREATE_COIN = "CREATE_COIN";
export const UPDATE_COIN = "UPDATE_COIN";
export const DELETE_CURATED_PROFILE = "DELETE_CURATED_PROFILE";
export const DELETE_COIN = "DELETE_COIN";

export const GET_VIDEOS = "GET_VIDEOS";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const VIDEO_ERROR = "VIDEO_ERROR";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FAILED_ALL_USER_FETCH = "FAILED_ALL_USER_FETCH";
export const FETCH_AUTHENTICATION_CODES = "FETCH_AUTHENTICATION_CODES";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";
export const UPDATE_USER_CLEARANCELEVEL = "UPDATE_USER_CLEARANCELEVEL";
export const FAILED_USER_UPDATE_PERMISSION = "FAILED_USER_UPDATE_PERMISSIO";
export const INVITE_USER = "INVITE_USER";
export const FAILED_USER_INVITE = "FAILED_USER_INVITE";
export const USER_DISCORD_SERVER = "USER_DISCORD_SERVER";

export const CREATE_VIDEO_TRACK = "CREATE_VIDEO_TRACK";
export const VIDEO_TRACK_ERROR = "VIDEO_TRACK_ERROR";

export const CREATE_MODULE = "CREATE_MODULE";
export const MODULE_ERROR = "MODULE_ERROR";
export const FETCH_MODULES = "FETCH_MODULES";
export const FETCH_MODULE = "FETCH_MODULE";
export const DELETE_MODULE = "DELETE_MODULE";
export const UPDATE_MODULE = "UPDATE_MODULE";

export const FETCH_TRACKS = "FETCH_TRACKS";
export const TRACK_ERROR = "TRACK_ERROR";
export const CREATE_TRACK = "CREATE_TRACK";
export const UPDATE_TRACK = "UPDATE_TRACK";
export const DELETE_TRACK = "DELETE_TRACK";

export const FETCH_BADGES = "FETCH_BADGES";
export const FETCH_BADGE = "FETCH_BADGE";
export const BADGE_ERROR = "BADGE_ERROR";
export const CREATE_BADGE = "CREATE_BADGE";
export const UPDATE_BADGE = "UPDATE_BADGE";
export const DELETE_BADGE = "DELETE_BADGE";

export const FETCH_PDFS = "FETCH_PDFS";
export const FETCH_PDF = "FETCH_PDF";
export const PDF_ERROR = "PDF_ERROR";
export const CREATE_PDF = "CREATE_PDF";
export const UPDATE_PDF = "UPDATE_PDF";
export const DELETE_PDF = "UPDATE_PDF";

export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const SEARCH_ERROR = "SEARCH_ERROR";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";

export const FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";
export const FETCH_ALL_LEADS = "FETCH_ALL_LEADS";
export const FAILED_TO_FETCH_ALL_ORDERS = "FAILED_TO_FETCH_ALL_ORDERS";

export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const FETCH_FEEDBACK = "FETCH_FEEDBACK";

export const FETCH_TAGS = "FETCH_TAGS";
export const TAGS_ERROR = "TAGS_ERROR";

export const FETCH_SHORT_LINKS = "FETCH_SHORT_LINKS";
export const FETCH_SHORT_LINK = "FETCH_SHORT_LINK";
export const SHORT_LINK_ERROR = "SHORT_LINK_ERROR";
export const CREATE_SHORT_LINK = "CREATE_SHORT_LINK";
export const UPDATE_SHORT_LINK = "UPDATE_SHORT_LINK";
export const DELETE_SHORT_LINK = "DELETE_SHORT_LINK";

export const FETCH_COINS_LIST = "FETCH_COINS_LIST";
export const CREATE_STAR = "CREATE_STAR";
export const COINS_LIST_ERROR = "COINS_LIST_ERROR";

export const GET_WEB3 = "GET_WEB3";
export const CREATE_WEB3 = "CREATE_WEB3";
export const DELETE_WEB3 = "DELETE_WEB3";
export const WEB3_ERROR = "WEB3_ERROR";

export const GET_MKT_EMAILS = "GET_MKT_EMAILS";
export const GET_MKT_EMAIL = "GET_MKT_EMAIL";
export const ADD_MKT_EMAIL = "ADD_MKT_EMAIL";
export const UPDATE_MKT_EMAIL = "UPDATE_MKT_EMAIL";
export const DELETE_MKT_EMAIL = "DELETE_MKT_EMAIL";


