import {
  CLEAR_SEARCH_RESULTS,
  CREATE_MODULE,
  CREATE_TRACK,
  DELETE_MODULE,
  DELETE_TRACK,
  FETCH_MODULE,
  FETCH_MODULES,
  FETCH_TRACKS,
  MODULE_ERROR,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  TRACK_ERROR,
  UPDATE_MODULE,
  UPDATE_TRACK,
} from "../actions/types";

const initialState = {
  modules: [],
  tracks: [],
  trackError: null,
  singleModule: {
    name: "",
    description: "",
    videos: [],
  },
  moduleError: null,
  moduleMessage: null,
  searchResult: null,
  searchError: null
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_MODULE:
      return {
        ...state,
        singleModule: action.payload,
      };
    case UPDATE_MODULE:
      return {
        ...state,
        singleModule: action.payload,
      };
    case MODULE_ERROR:
      return {
        ...state,
        moduleError: action.payload,
      };
    case TRACK_ERROR:
      return {
        ...state,
        trackError: action.payload,
      };
    case CREATE_TRACK:
      return {
        ...state,
        track: action.payload,
      };
    case UPDATE_TRACK:
      return {
        ...state,
        track: action.payload,
      };
    case DELETE_TRACK:
      return {
        ...state,
        track: action.payload,
      };
    case FETCH_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    case FETCH_TRACKS:
      return {
        ...state,
        tracks: action.payload && Array.isArray(action.payload) ? action.payload : [],
      };
    case FETCH_MODULE:
      return {
        ...state,
        singleModule: action.payload,
      };
    case DELETE_MODULE:
      return {
        ...state,
        singleModule: action.payload,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        searchError: action.payload,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResult: null,
      };
    default:
      return state;
  }
}
